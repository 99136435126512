:root {
  --color-white: #fff;
  --color-black: #111;
  --bg-color: #000225;
  --hot-one: hsl(0, 96%, 49%);
  --hot-two: hsl(0, 89%, 30%);
  --new-one: hsl(75, 88%, 42%);
  --new-two: hsl(78, 89%, 35%);
  --pop-one: hsl(207, 69%, 50%);
  --pop-two: hsl(212, 67%, 36%);
  --spo-one: hsl(38, 96%, 49%);
  --spo-two: hsl(34, 89%, 30%);
  --box-shadow: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
}
